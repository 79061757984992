import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    EditButton,
    SavedQueriesList,
    FilterLiveSearch,
    FilterList,
    FilterListItem,
} from 'react-admin';
import { Card, CardContent } from '@mui/material';
import MailIcon from '@mui/icons-material/MailOutline';

export const UsersFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 'max-content' }}>
        <CardContent>
            <SavedQueriesList />

            <FilterLiveSearch label='Email' source='email__ilike' variant='outlined' />
            <FilterLiveSearch label='Username' source='username__ilike' variant='outlined' />
            <FilterLiveSearch label='First Name' source='first_name__ilike' variant='outlined' />
            <FilterLiveSearch label='Last Name' source='last_name__ilike' variant='outlined' />

            <FilterList label='Verified Email' icon={<MailIcon />}>
                <FilterListItem label='Yes' value={{ verified_email: true }} />
                <FilterListItem label='No' value={{ verified_email: false }} />
            </FilterList>
        </CardContent>
    </Card>
);

const UsersList = (props: any) => {
    return (
        <List {...props} aside={<UsersFilterSidebar />}>
            <Datagrid>
                <TextField source='id' />
                <TextField source='first_name' />
                <TextField source='last_name' />
                <EmailField source='email' />
                <TextField source='language' />
                <TextField source='type' />
                <TextField source='subtype' />
                <TextField source='phone' />
                <BooleanField source='verified_email' />
                <BooleanField source='receive_notifications' />
                <BooleanField source='show_slides' />
                <TextField source='username' />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default UsersList;
