import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    SavedQueriesList,
    FilterLiveSearch,
    FilterList,
    FilterListItem,
} from 'react-admin';

import { Card, CardContent } from '@mui/material';

export const ExternalUsersFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 'max-content' }}>
        <CardContent>
            <SavedQueriesList />

            <FilterLiveSearch label='Name' source='name__ilike' variant='outlined' />
        </CardContent>
    </Card>
);

const ExternalUsersList = (props: any) => {
    return (
        <List {...props} aside={<ExternalUsersFilterSidebar />}>
            <Datagrid>
                <TextField source='id' />
                <TextField source='name' />
                <TextField source='description' />
                <TextField source='client_id' />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default ExternalUsersList;
