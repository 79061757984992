import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    Datagrid,
    DeleteButton,
    SelectField,
    useRecordContext,
} from 'react-admin';
import { COUNTRIES, USER_SUBTYPES, USER_TYPES } from '../choices';
import ManyToManyInput from './forms/ManyToManyInput';
import OnlySaveToolbar from './toolbar/OnlySaveToolbar';

const EditForm = () => {
    const record = useRecordContext();
    return (
        <SimpleForm toolbar={<OnlySaveToolbar />}>
            <TextInput source='id' disabled />
            <TextInput source='email' disabled />
            <TextInput source='username' disabled />
            <BooleanInput source='has_usable_password' disabled />
            <TextInput source='first_name' />
            <TextInput source='last_name' />
            <TextInput source='language' />
            <SelectInput source='type' choices={USER_TYPES} required={true} />
            <SelectInput source='subtype' choices={USER_SUBTYPES} />
            <TextInput source='phone' />
            <SelectInput source='country' choices={COUNTRIES} required={true} />
            <BooleanInput source='verified_email' />
            <BooleanInput source='receive_notifications' />
            <BooleanInput source='show_slides' />
            <ManyToManyInput reference='groups' referenceField='group_id' label='Groups' />
            <ManyToManyInput
                reference='internal-app-instances'
                referenceField='app_id'
                label='Internal Apps Instances'
                addChildren={
                    <ReferenceInput label='Internal Apps Instances' source='app_id' reference='tenant/app-instances'>
                        <AutocompleteInput
                            optionText={(appInstance) =>
                                `${appInstance.app.name} - ${
                                    COUNTRIES.find((c) => c.id === appInstance.country_id)?.name
                                }`
                            }
                            optionValue='id'
                        />
                    </ReferenceInput>
                }
                tableChildren={
                    <Datagrid isRowSelectable={() => false}>
                        <TextField source='id' />
                        <TextField source='app.name' />
                        <SelectField source='country_id' choices={COUNTRIES} />
                        <DeleteButton
                            mutationOptions={{
                                meta: {
                                    resource: 'users',
                                    resourceId: record.id,
                                },
                            }}
                            redirect={false}
                        />
                    </Datagrid>
                }
            />
        </SimpleForm>
    );
};

const UserEdit = (props: any) => {
    return (
        <Edit title='Edit a User' {...props}>
            <EditForm />
        </Edit>
    );
};

export default UserEdit;
