import { Card, CardContent, CardHeader } from '@mui/material';
import React, { Fragment, useState } from 'react';
import {
    ReferenceManyField,
    Datagrid,
    TextField,
    Button,
    ReferenceInput,
    AutocompleteInput,
    WithRecord,
    Create,
    useRefresh,
    useResourceContext,
    Pagination,
} from 'react-admin';

import { DeleteButton, SimpleForm } from 'react-admin';
import SimpleDialog from '../others/SimpleDialog';

export const AddNewManyToManyButton = ({
    record,
    reference,
    referenceField,
    label,
    children,
}: {
    record: any;
    reference: string;
    referenceField: string;
    label: string;
    children?: any;
}) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    return (
        <Fragment>
            <Button
                variant='contained'
                label={`Add ${label}`}
                onClick={() => setOpen(true)}
                sx={{ m: 1, p: 2, width: '100%' }}
            ></Button>
            <SimpleDialog open={open} onClose={() => setOpen(false)} title={label}>
                <Create
                    mutationOptions={{
                        meta: {
                            record,
                            referenceField,
                            reference,
                        },
                        onSuccess: () => {
                            setOpen(false);
                            refresh();
                        },
                    }}
                    redirect={false}
                    title={` => Add ${label}`}
                >
                    <SimpleForm>
                        {children ? (
                            children
                        ) : (
                            <ReferenceInput label={label} source={referenceField} reference={reference}>
                                <AutocompleteInput optionText='name' />
                            </ReferenceInput>
                        )}
                    </SimpleForm>
                </Create>
            </SimpleDialog>
        </Fragment>
    );
};

export const ManyToManyInput = ({
    reference,
    referenceField,
    label,
    tableChildren,
    addChildren,
}: {
    reference: string;
    referenceField: string;
    label: string;
    tableChildren?: any;
    addChildren?: any;
}) => {
    const resource = useResourceContext();
    return (
        <Card>
            <CardHeader title={label} />
            <CardContent>
                <WithRecord
                    render={(record: any) => (
                        <>
                            <ReferenceManyField
                                reference={reference}
                                target={resource}
                                pagination={<Pagination />}
                                perPage={10}
                            >
                                {tableChildren ? (
                                    tableChildren
                                ) : (
                                    <Datagrid isRowSelectable={() => false}>
                                        <TextField source='id' />
                                        <TextField source='name' />
                                        <DeleteButton
                                            mutationOptions={{
                                                meta: {
                                                    resource: resource,
                                                    resourceId: record.id,
                                                },
                                            }}
                                            redirect={false}
                                        />
                                    </Datagrid>
                                )}
                            </ReferenceManyField>
                            <AddNewManyToManyButton
                                record={record}
                                label={label}
                                reference={reference}
                                referenceField={referenceField}
                            >
                                {addChildren}
                            </AddNewManyToManyButton>
                        </>
                    )}
                />
            </CardContent>
        </Card>
    );
};

export default ManyToManyInput;
