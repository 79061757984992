import { Dialog, DialogTitle } from '@mui/material';

function SimpleDialog(props: { open: boolean; onClose: any; title: string; children: any }) {
    const { open, title, children, onClose } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            {children}
        </Dialog>
    );
}

export default SimpleDialog;
