import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

const OnlySaveToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export default OnlySaveToolbar;
