import { Edit, SimpleForm, TextInput } from 'react-admin';
import ManyToManyInput from './forms/ManyToManyInput';

const GroupEdit = (props: any) => {
    return (
        <Edit title='Edit a Group' {...props}>
            <SimpleForm>
                <TextInput source='name' />
                <ManyToManyInput
                    reference='permissions'
                    referenceField='permission_id'
                    label='Permissions'
                ></ManyToManyInput>
            </SimpleForm>
        </Edit>
    );
};

export default GroupEdit;
