export const COUNTRIES = [
    { id: 9683, name: 'PY' },
    { id: 4063, name: 'BR' },
    { id: 1, name: 'AR' },
    { id: 9920, name: 'MX' },
    { id: 9663, name: 'UY' },
];

export const LANGUAGES = [
    { id: 'es', name: 'ES' },
    { id: 'en', name: 'EN' },
    { id: 'pt', name: 'PT' },
];

export const USER_TYPES = [
    { id: 'bayer', name: 'Bayer' },
    { id: 'dealer', name: 'Dealer' },
    { id: 'agronomic_adviser', name: 'Agronomic adviser' },
    { id: 'farmer', name: 'Farmer' },
    { id: 'cooperative', name: 'Cooperative' },
    { id: 'student', name: 'Student' },
    { id: 'commercial', name: 'Commercial representative' },
    { id: 'other', name: 'Other' },
];

export const USER_SUBTYPES = [
    { id: 'sales', name: 'Sales' },
    { id: 'marketing', name: 'Marketing' },
    { id: 'md_mdr', name: 'MD - MDR' },
    { id: 'md_mda', name: 'MD - MDA' },
    { id: 'md_crops', name: 'MD - Crops' },
    { id: 'md_kt', name: 'MD - Knowledge transfer' },
    { id: 'md_other', name: 'MD - Other' },
    { id: 'other', name: 'Other' },
];

export const UNIT_SYSTEMS = [
    { id: 'metric', name: 'metric' },
    { id: 'imperial', name: 'imperial' },
];

export const UNIT_AREAS = [
    { id: 'ha', name: 'ha' },
    { id: 'alq', name: 'alq' },
    { id: 'ac', name: 'ac' },
];

export const UNIT_YIELDS = [
    { id: 'kg', name: 'kg' },
    { id: 'lb', name: 'lb' },
    { id: 'ton', name: 'ton' },
    { id: 'qq', name: 'qq' },
    { id: 'sacas', name: 'sacas' },
    { id: 'bu', name: 'bu' },
];

export const UNIT_CORN_DENSITIES = [
    { id: 'ha', name: 'ha' },
    { id: 'alq', name: 'alq' },
    { id: 'ac', name: 'ac' },
    { id: 'm2', name: 'm2' },
    { id: 'm', name: 'm' },
];

export const UNIT_SOY_DENSITIES = [
    { id: 'ha', name: 'ha' },
    { id: 'alq', name: 'alq' },
    { id: 'ac', name: 'ac' },
    { id: 'm2', name: 'm2' },
    { id: 'm', name: 'm' },
];
