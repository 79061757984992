import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const PermissionCreate = (props: any) => {
    return (
        <Create title='Create a Permission' {...props}>
            <SimpleForm>
                <TextInput source='name' />
            </SimpleForm>
        </Create>
    );
};

export default PermissionCreate;
