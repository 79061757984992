import React from 'react';
import { Create, SimpleForm, TextInput, useCreateContext } from 'react-admin';

const ExternalUserCreateForm = () => {
    const { save } = useCreateContext();

    const onCreate = (data: any) => {
        if (data.client_secret === '') delete data.client_secret;
        if (save) save(data);
    };

    return (
        <SimpleForm onSubmit={onCreate}>
            <TextInput source='name' required />
            <TextInput source='description' required />
            <TextInput source='client_secret' />
        </SimpleForm>
    );
};

const ExternalUserCreate = (props: any) => {
    return (
        <Create title='Create a User'>
            <ExternalUserCreateForm />
        </Create>
    );
};

export default ExternalUserCreate;
