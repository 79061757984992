import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const GroupEdit = (props: any) => {
    return (
        <Edit title='Edit a Group' {...props}>
            <SimpleForm>
                <TextInput source='id' disabled />
                <TextInput source='name' />
            </SimpleForm>
        </Edit>
    );
};

export default GroupEdit;
