import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const GroupCreate = (props: any) => {
    return (
        <Create title='Create a Group'>
            <SimpleForm>
                <TextInput source='name' />
            </SimpleForm>
        </Create>
    );
};

export default GroupCreate;
