import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, SavedQueriesList, FilterLiveSearch } from 'react-admin';
import { Card, CardContent } from '@mui/material';

export const PermissionsFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 'max-content' }}>
        <CardContent>
            <SavedQueriesList />

            <FilterLiveSearch label='Name' source='name__ilike' variant='outlined' />
        </CardContent>
    </Card>
);

const PermissionsList = (props: any) => {
    return (
        <List {...props} aside={<PermissionsFilterSidebar />}>
            <Datagrid>
                <TextField source='id' />
                <TextField source='name' />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default PermissionsList;
