import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ManyToManyInput from './forms/ManyToManyInput';

const ExternalUserEdit = (props: any) => {
    return (
        <Edit title='Edit a User'>
            <SimpleForm>
                <TextInput source='id' />
                <TextInput source='name' />
                <TextInput source='description' />
                <TextInput source='client_id' />
                <ManyToManyInput reference='groups' referenceField='group_id' label='Groups' />
            </SimpleForm>
        </Edit>
    );
};

export default ExternalUserEdit;
