import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SavedQueriesList,
    FilterLiveSearch,
    DeleteButton,
    DateField,
    TranslatableFields,
    ArrayField,
} from 'react-admin';
import { Card, CardContent } from '@mui/material';

export const NotificationsFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 'max-content' }}>
        <CardContent>
            <SavedQueriesList />
            <FilterLiveSearch label='Username' source='username__ilike' variant='outlined' />
            <FilterLiveSearch label='Category' source='content.category__ilike' variant='outlined' />
        </CardContent>
    </Card>
);

const NotificationsList = (props: any) => {
    return (
        <List {...props} aside={<NotificationsFilterSidebar />}>
            <Datagrid>
                <TextField source='content.category' label='Category' />
                <TranslatableFields locales={['en', 'es', 'pt']} defaultLocale='es'>
                    <TextField source='content.content' label='Content' />
                </TranslatableFields>
                <DateField source='createdAt' label='Created' />
                <DateField source='updatedAt' label='Updated' />
                <DateField source='readedOn' label='Readed On' />
                <TextField source='username' label='Username' />
                <TextField source='sender' label='Sender' />
                <TextField source='appIds' label='appIds' />
                <TextField source='countryIds' label='countryIds' />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default NotificationsList;
